.sectionWrapper {
  display: flex;
  width: 100vw;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.whiteBox {
  display: flex;
  width: 1140px;
  padding: 20px 100px 20px 100px;
  flex-direction: row;
  align-items: center;
  border-radius: 60px;
  background: #FFF;
  border: 2px solid #ED765E;
}

.infoTextContainer {
  display: flex;
  padding: 30px 0px;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.infoTextContainerTeam {
  display: flex;
  padding: 30px 0px;
  flex-direction: column;
  align-items: flex-start;
  width: 65%;
}

.infoImageContainer {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.infoImageContainerTeam {
  display: flex;
  width: 35%;
  flex-direction: column;
  align-items: center;
}

.infoHeadingContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  align-items: flex-start;
  gap: 10px;
}

.infoHeading {
  color: #292D34;
  font-family: Segoe UI;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: 65px;
  /* 135.417% */
}

.infoDescriptionContainer {
  display: flex;
  align-items: flex-start;
}

.infoDescription {
  color: #292D34;
  font-family: Segoe UI;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  padding-top: 5%;
  /* 192.308% */
}

.infoButtonContainer {
  display: flex;
  padding: 30px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.infoButton {
  padding: 15px 20px;
  align-items: flex-start;
  border-radius: 5px;
  background: linear-gradient(75deg, #ED765E 0%, #FEA858 50%, #EF7D5D 100%);
  box-shadow: 0px 9px 28px 0px rgba(16, 30, 54, 0.15), 0px 6px 30px 0px rgba(0, 0, 0, 0.25);
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  transition: background-color 0.5s;
}

.infoButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ED765E;
  z-index: 1;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s;
}

.infoButton:hover::before {
  transform: scaleX(1);
  transition: transform 0.5s;
}

.infoButtonText {
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 10px;
  /* 71.429% */
  text-transform: uppercase;
  z-index: 2;
}

.infoSubHeading {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 47.5px;
  /* 197.917% */
}

/*-----------------------------------------------------------------------------------------*/

@media only screen and (max-width: 768px) {
  .whiteBox {
    flex-direction: column;
    padding: 20px 20px;
    /* Adjust the padding as needed */
    width: calc(100% - 40px);
    /* Subtract the sum of horizontal padding from the total width */
  }

  .infoTextContainer,
  .infoTextContainerTeam {
    display: flex;
    padding: 30px 0px;
    flex-direction: column;
    align-items: center;
    /* Center align all the items */
    text-align: center;
    /* Center align text content */
    width: 90%;
  }

  .infoImageContainer,
  .infoImageContainerTeam {
    width: 100%;
  }

  .infoDescription {
    padding-top: 2%;
  }

  .infoHeading {
    font-size: 36px;
    line-height: 50px;
    text-align: center;
  }

  .infoDescription {
    font-size: 12px;
    line-height: 20px;
  }

  .infoButtonContainer {
    padding: 20px 0px;
  }
}

/*************************************************************************************************************************/

@media screen and (min-width: 768px) and (max-width: 1024px) {

  .whiteBox {
    flex-direction: column;
    padding: 20px 20px;
    /* Adjust the padding as needed */
    width: calc(100% - 40px);
    /* Subtract the sum of horizontal padding from the total width */
  }

  .infoTextContainer,
  .infoTextContainerTeam {
    display: flex;
    padding: 30px 0px;
    flex-direction: column;
    align-items: center;
    /* Center align all the items */
    text-align: center;
    /* Center align text content */
    width: 90%;
  }

  .infoImageContainer,
  .infoImageContainerTeam {
    width: 100%;
  }

  .infoDescription {
    padding-top: 2%;
  }

  .infoHeading {
    font-size: 36px;
    line-height: 50px;
    text-align: center;
  }

  .infoDescription {
    font-size: 16px;
    line-height: 20px;
  }

  .infoButtonContainer {
    padding: 20px 0px;
  }
}