.sectionWrapper {
    display: flex;
    width: 100vw;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.ninestepsHeadingContainer {
    display: flex;
    padding: 40px 40px 40px 0px;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
}

.ninestepsHeading {
    display: flex;
    flex-direction: row;
}

.ninestepsHeadingone {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
    line-height: 47.5px;
    margin-right: 10px;
    /* 103.261% */
}

.ninestepsHeadingtwo {
    color: #ED765E;
    font-family: Inter;
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
    line-height: 47.5px;
}

.ninestepsRow {
    display: flex;
    padding-top: 40px;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
}

.stepContainer {
    display: flex;
    width: 360px;
    padding: 30px 20px 0px 20px;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    border: 1px solid #ED765E;
    background: #FBF9F8;
}

.stepContainer:hover {
    background: #ED765E;
}

.stepCircle {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    position: relative;
    background-color: #FBF9F8;
    border: 5px solid #FF6900;
    z-index: 10;
}

.stepNumber {
    color: #FF6900;
    text-align: center;
    position: absolute;
    top: 15%;
    left: 35%;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    /* 69.444% */
}

.steptextContainer {
    display: flex;
    padding: 15px 0px 25px 0px;
    flex-direction: column;
    align-items: center;
    gap: 7px;
}

.stepHeading {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 25px;
    /* 125% */
}

.stepDescContainer {
    display: flex;
    padding: 0px 12.5px 1px 0px;
    align-items: flex-start;
}

.stepDesc {
    color: #333;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

/*--------------------------------------------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 768px) {
    .sectionWrapper {
        padding: 20px 0;
    }

    .ninestepsHeadingContainer {
        padding: 20px;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 15px;
    }

    .ninestepsHeading {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 5px;
    }

    .ninestepsHeadingone {
        font-size: 28px;
        line-height: 30px;
        margin-right: 0;
    }

    .ninestepsHeadingtwo {
        font-size: 28px;
        line-height: 30px;
    }

    .ninestepsRow {
        padding-top: 20px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .stepContainer {
        width: calc(100% - 40px);
        padding: 20px;
    }

    .stepCircle {
        display: none;
    }

    .steptextContainer {
        padding: 15px 0 15px 0;
        align-items: center;
        text-align: center;
    }

    .stepHeading {
        font-size: 18px;
        line-height: 22px;
    }

    .stepDescContainer {
        padding: 0;
    }

    .stepDesc {
        font-size: 14px;
        line-height: 20px;
    }
}

/*********************************************************************************************************************************/

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .sectionWrapper {
        padding: 20px 0;
    }

    .ninestepsHeadingContainer {
        padding: 20px;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 15px;
    }

    .ninestepsHeading {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 5px;
    }

    .ninestepsHeadingone {
        font-size: 28px;
        line-height: 30px;
        margin-right: 0;
    }

    .ninestepsHeadingtwo {
        font-size: 28px;
        line-height: 30px;
    }

    .ninestepsRow {
        padding: 20px 5vw;
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }

    .stepContainer {
        width: calc(100% - 80px);
        padding: 20px;
        height: 30vw;
    }

    .stepCircle {
        display: none;
    }

    .steptextContainer {
        padding: 15px 0 15px 0;
        align-items: center;
        text-align: center;
    }

    .stepHeading {
        font-size: 18px;
        line-height: 22px;
    }

    .stepDescContainer {
        padding: 0;
    }

    .stepDesc {
        font-size: 14px;
        line-height: 20px;
    }
}