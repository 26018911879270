.footerWrapper {
    width: 100vw;
    display: flex;
    right: 0;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    background: linear-gradient(180deg, #ED765E 0%, #FEA858 33.85%, #FEA858 68.75%, #ED765E 100%);
}

.footerContainer {
    display: flex;
    width: 80vw;
    padding: 0px 10px;
    flex-direction: column;
    align-items: flex-start;
}

.footerTop {
    display: flex;
    padding: 20px 0.01px 20px 0px;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
}

.footerTopItemWrapper {
    display: flex;
    padding: 15px;
    align-items: center;
}

.footerTopItem {
    display: flex;
    align-items: center;
    gap: 10px;
}

.footerTopItemSVG {
    display: flex;
    width: 46px;
    max-width: 46px;
    justify-content: center;
    align-items: flex-start;
}

.footerTopItemBody {
    display: flex;
    height: 24px;
    align-items: center;
    gap: 12px;
}

.footerTopItemBold {
    color: #FFF;
    font-family: Segoe UI;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
}

.footerTopItemNormal {
    color: #FFF;
    font-family: Segoe UI;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.footerList {
    display: flex;
    padding-top: 0.8px;
    align-items: flex-start;
    align-content: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
}

.footerItem {
    display: flex;
    padding: 39.4px 12px 50px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20.6px;
    flex: 1 0 0;
}

.footerItemheading {
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 19.5px;
    /* 162.5% */
    text-transform: uppercase;
}

.footerItemBody {
    display: flex;
    width: 209.2px;
    padding-right: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.footerItemList {
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 175% */
}

.footerBottomContainer {
    display: flex;
    width: 1220px;
    max-width: 1220px;
    justify-content: space-between;
    align-items: center;
}

.footerBottomContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7.4px;
}

.footerBottomLogo {
    display: flex;
    width: 106.354px;
    height: 26.28px;
    padding: 2px 1px;
    align-items: flex-start;
    gap: 8px;
}

.footerBottomContentLinks {
    display: flex;
    flex-direction: row;
    padding-right: 0px;
    align-items: flex-end;
    gap: 8px;
}

.footerBottomContentLinkContainer {
    display: flex;
    height: 18px;
    padding: 2.4px 0px;
    align-items: flex-end;
}

.footerBottomContentLink {
    display: flex;
    height: 12px;
    flex-direction: row;
    justify-content: center;
    flex-shrink: 0;
    color: #FFF;
    font-family: Segoe UI;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */
}

.footerBottomSocialLinks {
    display: flex;
    align-items: center;
    gap: 16px;
}

.footerBottomSocialLinkContainer {
    display: flex;
    padding-bottom: 0px;
    justify-content: center;
    align-items: flex-start;
}

.footerBottomSocialSVGContainer {
    display: flex;
    width: 24px;
    height: 24px;
    align-items: flex-start;
}

.footerBottomSocialSVG {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

  .footerBottomSocialSVGContainer img:hover {
    filter: brightness(0%);
  }

  .footerBottomSocialSVGContainer {
    display: flex;
    width: 24px;
    height: 24px;
    align-items: flex-start;
    transition: transform 0.3s;
}

.footerBottomSocialSVGContainer:hover {
    transform: translateY(-5px);
}


/*------------------------------------------------------------------------------------------------------------------------*/

/* Media query for screens with a maximum width of 768px */
@media only screen and (max-width: 768px) {
    .footerWrapper {
        padding: 30px 0;
    }

    .footerContainer {
        padding-left: 12%;
        padding-right: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
    }

    .footerTop {
        display: flex;
        flex-direction: column;
        width: 70vw;
        padding-left: 15%;
        padding-right: 12px;
        gap: 30px;
        padding-bottom: 15%;
    }

    .footerTopItemWrapper {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footerTopItem {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footerTopItemSVG {
        width: 28px;
        max-width: 36px;
    }

    .footerItem {
        padding: 30px 12px 30px 12px;
    }

    .footerTopItemBody {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .footerTopItemBold {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        text-align: left;
        /* 150% */
    }

    .footerTopItemNormal {
        font-size: 16px;
        font-style: normal;
        line-height: 16px;
        text-align: left;
        /* 150% */
    }

    .footerTopItemBody {
        gap: 8px;
    }

    .footerItemheading {
        font-size: 14px;
        line-height: 24px;
    }

    .footerItemBody {
        width: auto;
    }

    .footerBottomContainer {
        display: flex;
        flex-direction: column;
        padding: 30px 20px;
        justify-content: center;
    }

    .footerBottomContent {
        align-items: center;
        gap: 10px;
    }

    .footerBottomLogo {
        width: 100px;
        height: auto;
    }

    .footerBottomContentLinks {
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }

    .footerBottomContentLinkContainer {
        height: auto;
        padding: 0;
    }

    .footerBottomContentLink {
        height: auto;
        font-size: 12px;
        line-height: 16px;
    }

    .footerBottomSocialLinks {
        gap: 20px;
        padding-top: 3%;
    }

    .footerBottomSocialLinkContainer {
        padding-bottom: 0;
    }
}

/********************************************************************************************************/

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .footerWrapper {
        padding: 30px 0;
    }

    .footerContainer {
        padding-left: 12%;
        padding-right: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
    }

    .footerTop {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 90vw;
        padding-left: 3%;
        padding-right: 20%;
        gap: 0px;
        padding-bottom: 15%;
    }

    .footerTopItemWrapper {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footerTopItem {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footerTopItemSVG {
        width: 28px;
        max-width: 36px;
    }

    .footerItem {
        padding: 30px 12px 30px 12px;
    }

    .footerTopItemBody {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .footerTopItemBold {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        text-align: left;
        /* 150% */
    }

    .footerTopItemNormal {
        font-size: 16px;
        font-style: normal;
        line-height: 16px;
        text-align: left;
        /* 150% */
    }

    .footerTopItemBody {
        gap: 8px;
    }

    .footerItemheading {
        font-size: 14px;
        line-height: 24px;
    }

    .footerItemBody {
        width: auto;
    }

    .footerBottomContainer {
        display: flex;
        flex-direction: column;
        padding: 30px 20px;
        justify-content: center;
    }

    .footerBottomContent {
        align-items: center;
        gap: 10px;
    }

    .footerBottomLogo {
        width: 100px;
        height: auto;
    }

    .footerBottomContentLinks {
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }

    .footerBottomContentLinkContainer {
        height: auto;
        padding: 0;
    }

    .footerBottomContentLink {
        height: auto;
        font-size: 12px;
        line-height: 16px;
    }

    .footerBottomSocialLinks {
        gap: 20px;
        padding-top: 3%;
    }

    .footerBottomSocialLinkContainer {
        padding-bottom: 0;
    }
}