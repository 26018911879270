.sectionWrapper {
    display: flex;
    width: 100vw;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.bgBox {
    display: flex;
    width: 1140px;
    padding-top: 50px;
    flex-direction: column;
    align-items: center;
    gap: 75px;
    border-radius: 60px;
    background: #F8F7FE;
}

.contentContainerBgbox {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: center;
    gap: -1.4px;
}

.contentWrapper {
    display: flex;
    max-width: 890px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
}

.headingContainer {
    display: flex;
    max-width: 900px;
    justify-content: center;
    align-items: flex-start;
}

.headingbg {
    color: #333;
    text-align: center;
    font-family: Segoe UI;
    width: 750px;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 65px;
    /* 135.417% */
}

.imageContainer {
    display: flex;
    width: 858px;
    height: 572px;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 20px;
}

.buttonContainer {
    display: flex;
    min-height: 65px;
    padding: 26px 66.12px 24px 116px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 0px 0px 60px 60px;
    background: #F2F0FD;
}

.buttonContainer:hover {
    background: #ddd9f6;
}

.buttonWrapper {
    display: flex;
    padding: 12.2px 19.99px 8.8px 25px;
    justify-content: center;
    align-items: center;
    gap: 14.78px;
}

.buttonText {
    color: #292D34;
    text-align: center;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    /* 150% */
}

.buttonSVG {
    display: flex;
    width: 16px;
    height: 10px;
    padding-bottom: 0px;
    align-items: flex-start;
}

/*+-------------------------------------------------------------------------------------------------------------------------------------+*/
@media screen and (max-width: 768px) {
    .bgBox {
      padding: 30px 20px;
      gap: 40px;
      width: calc(100% - 40px);
      background-image: none;
    }
  
    .headingbg {
      font-size: 32px;
      width: 100%;
    }
  
    .imageContainer {
      width: 100%;
      height: auto;
    }
  
    .buttonContainer {
      padding: 20px 40px 20px 40px;
      border-radius: 40px;
    }
  
    .buttonWrapper {
      padding: 10px 15px 10px 15px;
    }
  }
  

  /***************************************************************************************************************************/

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .bgBox {
        padding: 30px 20px;
        gap: 40px;
        width: calc(100% - 40px);
        background-image: none;
      }
    
      .headingbg {
        font-size: 32px;
        width: 100%;
      }
    
      .imageContainer {
        width: 100%;
        height: auto;
      }
    
      .buttonContainer {
        padding: 20px 40px 20px 40px;
        border-radius: 40px;
      }
    
      .buttonWrapper {
        padding: 10px 15px 10px 15px;
      }
    }
