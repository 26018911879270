/*----------------------------------------*\
#GOOGLE FONTS
\*----------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;800&family=Open+Sans:wght@700&display=swap');
/* App.css */

html {
  overflow-x: hidden;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  overflow-x: hidden;
}

/* Styles for the navbar */
.navbar {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 15px 0;
  z-index: 1000;
  /* Other styles for your navbar */
}

/* Styles for the content area below the navbar */
.content-container {
  padding-top: 70px;
  /* Height of the navbar (adjust this value as needed) */
  /* Additional styles for the content container */
}


/*----------------------------------------*\
#CSS VARIABLES
\*----------------------------------------*/

:root {
  /**
   *colors
   */

  --primary-color: hsl(165, 60%, 40%);
  --title-color: hsl(0, 0%, 100%);
  --text-color: hsl(0, 0%, 0%);
  --bg-color: #EBEEF4;
  --bg-color-alt: hsl(213, 10%, 21%);
  --container-color: hsl(217, 18%, 14%);
  --border-color: hsl(210, 2%, 65%);
  --first-gradient: linear-gradient(0deg,
      var(--bg-color-alt) 0%,
      var(--bg-color) 100% overflow-x: hidden;
    );
  --second-gradient: linear-gradient(180deg,
      var(--bg-color-alt) 0%,
      var(--bg-color) 100%);
  --third-gradient: linear-gradient(180deg,
      var(--bg-color-alt) 0%,
      var(--bg-color) 100%);

  /**
   *typography
   */

  --body-font: 'Inter';
  --second-font: 'Inter';

  --biggest-font-size: clamp(2.25rem, 1.125rem + 5.1429vw, 5.625rem);
  --h1-font-size: clamp(1.75rem, 1.4167rem + 1.5238vw, 2.75rem);
  --h2-font-size: 30px;
  --h3-font-size: 28px;
  --h4-font-size: 24px;
  --h5-font-size: 22px;
  --largest-font-size: 21px;
  --larger-font-size: 20px;
  --large-font-size: 18px;
  --normal-font-size: 16px;
  --small-font-size: 15px;
  --smaller-font-size: 14px;
  --tiny-font-size: 13px;

  /**
   *shadow
   */

  --shadow: 5px 5px rgb(255 255 255 / 10%);

  /**
   *transition
   */

  --transition: cubic-bezier(0.3, 0, 0.3, 1);
}

/*----------------------------------------*\
#LIGHT THEME
\*----------------------------------------*/


/*----------------------------------------*\
#RESET
\*----------------------------------------*/



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button,
input,
body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: 600;
}

body {
  color: var(--text-color);
  background-color: var(--bg-color);
}

body.no-scroll {
  overflow: hidden;
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: 700;
}

ul {
  list-style: none;
}

p {
  line-height: 1.7;
}

a {
  text-decoration: none;
}

input,
textarea,
button {
  border: none;
  outline: none;
  background: transparent;
}

button {
  cursor: pointer;
}

img {
  max-width: 100%;
}

/*----------------------------------------*\
#REUSABLE CSS CLASSES
\*----------------------------------------*/


.container {
  max-width: 1300px;
  width: 100vw;
  padding-inline: 12px !important;
  margin-inline: auto;
}

@media screen and (max-width: 768px) {
  html {
    overflow-x: hidden;
  }
}