.sub-navbar {
  background-color: transparent;
  padding: 15px 0;
}

.navtabcontainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.navtablink {
  color: black;
  margin-right: 20px;
  text-decoration: none;
  position: relative;
}

.navtablink button:hover {
  color: #49CCF9;
}

/* Sliding indicator for active link */
.navtablink button.active::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: black;
}

/****************************************************************************************************************************************/


@media screen and (max-width: 768px) {
  .sub-navbar {
    width: 80vw;
  }

  .navtabcontainer {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    width: 80vw;
    gap: 5%;
  }

  .navtablink {
    color: black;
    margin-right: 20px;
    text-decoration: none;
    position: relative;
    padding-top: 5%;
    text-align: center;
  }

  .navtablink button:hover {
    color: #49CCF9;
  }

  /* Sliding indicator for active link */
  .navtablink button.active::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    background-color: black;
  }
}