.sectionWrapper {
    display: flex;
    width: 100vw;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.communityBox {
    display: flex;
    max-width: 1140px;
    padding-top: 80px;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    border-radius: 60px;
    background: #F0FBF8;
}

.communityContainer {
    display: flex;
    padding: 0px 30px;
    align-items: flex-end;
}

.ctextContainer {
    display: flex;
    width: 40%;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}

.chero {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.cheroHeadings {
    display: flex;
    width: 100%;
    padding: 0.39px 24.4px 0px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 19.61px;
}

.cheroTopHeading {
    color: #00B884;
    font-family: Segoe UI;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    /* 141.667% */
    letter-spacing: 1px;
    text-transform: uppercase;
}

.cheroHeadingWrapper {
    display: flex;
    width: 100%;
    padding-right: 0px;
    align-items: flex-start;
}

.cheroHeading {
    color: #333;
    font-family: Segoe UI;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 65px;
    /* 135.417% */
}

.cheroDescWrapper {
    display: flex;
    padding: 0px 24px 0.4px 0px;
    align-items: flex-start;
}

.cheroDesc {
    color: #292D34;
    font-family: Segoe UI;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    /* 192.308% */
}

.citemList {
    display: flex;
    width: 440px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 15px 0px;
    flex-wrap: wrap;
}

.citemListItem {
    display: flex;
    width: 220px;
    padding-right: 0px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
}

.citemIcon {
    width: 25px;
    height: 25px;
    min-width: 25px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #00B884;
}

.citemTextWrapper {
    display: flex;
    max-width: 120px;
    padding: 0px 73px 0.61px 0px;
    align-items: flex-start;
    flex: 1 0 0;
}

.citemText {
    color: #292D34;
    font-family: Segoe UI;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 150% */
    text-transform: uppercase;
}

.cimageContainer {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
}

.cimage {
    width: 100%;
}

.cbuttonContainer {
    display: flex;
    min-height: 65px;
    padding: 26px 66.12px 24px 116px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 0px 0px 60px 60px;
    background: #E4F8F2;
}

.cbuttonContainer:hover {
    background: #cff5e9;
}

.buttonWrapper {
    display: flex;
    padding: 12.2px 19.99px 8.8px 25px;
    justify-content: center;
    align-items: center;
    gap: 14.78px;
}

.buttonText {
    color: #292D34;
    text-align: center;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    /* 150% */
}

.buttonSVG {
    display: flex;
    width: 16px;
    height: 10px;
    padding-bottom: 0px;
    align-items: flex-start;
}

/*--------------------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 768px) {
    .sectionWrapper {
        padding: 40px 10px;
    }

    .communityBox {
        width: calc(100% - 40px);
    }

    .communityContainer {
        display: flex;
        flex-direction: column;
    }

    .ctextContainer {
        width: calc(100vw - 120px);
        align-items: center;
    }

    .cheroHeadings {
        padding: 0;
        text-align: center;
        align-items: center;
        gap: 15px;
    }

    .cheroHeading {
        font-size: 32px;
        line-height: 1.2;
    }

    .cheroDesc {
        text-align: center;
        padding: 0;
    }

    .citemList {
        align-items: center;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto;
        width: 80vw;
    }

    .citemListItem {
        display: flex;
        align-items: center;
        width: 35vw;
        justify-content: center;
    }


    .citemIcon {
        width: 20px;
        height: 20px;
        min-width: 20px;
    }

    .cimageContainer {
        padding: 40px 0;
    }

    .cbuttonContainer {
        padding: 20px 40px;
        border-radius: 20px;
    }

    .buttonWrapper {
        gap: 10px;
    }

    .buttonText {
        font-size: 16px;
    }

    .buttonSVG {
        width: 12px;
        height: 8px;
    }
}

/***********************************************************************************************************************/

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .sectionWrapper {
        padding: 40px 10px;
    }

    .communityBox {
        width: calc(100% - 40px);
    }

    .communityContainer {
        display: flex;
        flex-direction: column;
    }

    .ctextContainer {
        width: calc(100vw - 120px);
        align-items: center;
    }

    .cheroHeadings {
        padding: 0;
        text-align: center;
        align-items: center;
        gap: 15px;
    }

    .cheroHeading {
        font-size: 32px;
        line-height: 1.2;
    }

    .cheroDesc {
        text-align: center;
        padding: 0;
    }

    .citemList {
        align-items: center;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto;
        width: 80vw;
    }

    .citemListItem {
        display: flex;
        align-items: center;
        width: 35vw;
        justify-content: center;
    }


    .citemIcon {
        width: 20px;
        height: 20px;
        min-width: 20px;
    }

    .cimageContainer {
        padding: 40px 0;
    }

    .cbuttonContainer {
        padding: 20px 40px;
        border-radius: 20px;
    }

    .buttonWrapper {
        gap: 10px;
    }

    .buttonText {
        font-size: 16px;
    }

    .buttonSVG {
        width: 12px;
        height: 8px;
    }

}