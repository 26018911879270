.sectionWrapper {
    display: flex;
    width: 100vw;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.communityBox {
    display: flex;
    max-width: 1140px;
    padding-top: 80px;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    border-radius: 60px;
    background: #F0FBF8;
}

.doubleBoxWrapper {
    display: flex;
    padding: 0px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.doubleBoxHeadingContainer {
    display: flex;
    width: 1000px;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 23px;
}

.doubleBoxHeadingWrapper {
    display: flex;
    width: 1000px;
    max-width: 1000px;
    padding: 0px 207.03px 0.25px 207.97px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.doubleBoxMainHeading {
    color: #000;
    text-align: center;
    font-family: Segoe UI;
    font-size: 41px;
    font-style: normal;
    font-weight: 900;
    line-height: 47.25px;
    /* 115.244% */
}

.doubleboxSubHeading {
    color: #333;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
}

.doubleBoxContentContainer {
    display: flex;
    padding: 0px 12px;
    align-items: flex-start;
    gap: 16px;
}

.boxOne {
    display: flex;
    padding: 0px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.boxHeadingWrapper {
    display: flex;
    width: 476px;
    flex-direction: column;
    align-items: flex-start;
}

.boxHeadingContainer {
    display: flex;
    width: 476px;
    padding-bottom: 10%;
    padding-top: 10%;
    flex-direction: column;
    align-items: center;
    gap: 7px;
}

.boxHeading {
    color: #00B884;
    text-align: center;
    font-family: Segoe UI;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 25px;
    /* 78.125% */
}

.boxHeadingTwo {
    color: #000;
    text-align: center;
    font-family: Segoe UI;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 25px;
    /* 78.125% */
}

.boxList {
    display: flex;
    width: 476px;
    flex-direction: column;
    align-items: flex-start;
}
.boxListKarma {
    display: flex;
    width: 476px;
    flex-direction: column;
    align-items: flex-start;
}

.boxItem {
    display: flex;
    width: 100%;
    padding-bottom: 10%;
    flex-direction: row;
    align-items: flex-start;
    gap: 7px;
}

.boxItemImage {
    width: 27px;
    height: 27px;
    max-width: 27px;
    max-height: 27px;
}

.boxItemText {
    color: #333;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
}

/****************************************************************************************************/

.boxItemText:hover .redHover {
    color: red;
    transition: color 1s;
    cursor: crosshair;
}

.boxItemText:hover .greenHover {
    color: #00B884;
    transition: color 1s;
    cursor: crosshair;
}


.cbuttonContainer {
    display: flex;
    min-height: 65px;
    padding: 26px 66.12px 24px 116px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 0px 0px 60px 60px;
    background: #E4F8F2;
}

.buttonWrapper {
    display: flex;
    padding: 12.2px 19.99px 8.8px 25px;
    justify-content: center;
    align-items: center;
    gap: 14.78px;
}

.buttonText {
    color: #292D34;
    text-align: center;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    /* 150% */
}

.buttonSVG {
    display: flex;
    width: 16px;
    height: 10px;
    padding-bottom: 0px;
    align-items: flex-start;
}

/*--------------------------------------------------------------------------------------------------------------*/

.doubleBoxContentWrapper {
    display: flex;
    flex-direction: column;
}

.doubleBoxContentMainHeadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.boxHeadingKarma {
    color: #333;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
}

.boxItemKarma {
    display: flex;
    width: 100%;
    padding-bottom: 5%;
    flex-direction: row;
    align-items: flex-start;
    gap: 7px;
}

/*---------------------------------------------------------------------------------------------------------*/

/* Media Query for Mobile Devices */
/* Media Query for Mobile Devices */
@media only screen and (max-width: 768px) {
    .communityBox {
        flex-direction: column;
        width: calc(100% - 40px);
        display: flex;
        padding-top: 5%;
        flex-direction: column;
    }

    .doubleBoxWrapper {
        padding: 0;
        justify-content: center;
        align-items: center;
    }

    .doubleBoxHeadingWrapper {
        padding: 0;
    }

    .doubleBoxMainHeading {
        padding-top: 5%;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
    }

    .boxHeadingKarma {
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        width: 80vw;
    }

    .doubleboxSubHeading {
        font-size: 12px;
        width: 80vw;
        line-height: 20px;
        text-align: center;
    }

    .doubleBoxContentContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .boxHeading {
        text-align: center;
        font-size: 24px;
        line-height: 30px;
    }

    .boxHeadingTwo {
        text-align: center;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
    }

    .boxOne {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .boxList {
        gap: 20px;
        width: 80vw;
    }

    .boxListKarma {
        gap: 20px;
        width: 80vw;
        padding-left: 5%;
    }

    .boxItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 5%;
    }

    .boxItemKarma {
        padding-bottom: 0%;
    }

    .boxItemImage {
        width: 20px;
        height: 20px;
        max-width: 20px;
        max-height: 20px;
    }

    .boxItemText {
        font-size: 12px;
        line-height: 18px;
    }

    /*---------------------------------------------*/

    /*---------------------------------------------*/

    .cbuttonContainer {
        padding: 16px 33.06px 14px 58px;
    }

    .buttonText {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
    }

    .buttonSVG {
        width: 12px;
        height: 8px;
    }

    .boxHeadingContainer{
        padding: 2%;
    }
}

/*************************************************************************************************************************/

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .communityBox {
        flex-direction: column;
        width: calc(100% - 40px);
        display: flex;
        padding-top: 5%;
        flex-direction: column;
    }

    .doubleBoxWrapper {
        padding: 0;
        justify-content: center;
        align-items: center;
    }

    .doubleBoxHeadingWrapper {
        padding: 0;
    }

    .doubleBoxMainHeading {
        padding-top: 5%;
        font-size: 32px;
        line-height: 30px;
        text-align: center;
    }

    .boxHeadingKarma {
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        width: 80vw;
    }

    .doubleboxSubHeading {
        font-size: 12px;
        width: 80vw;
        line-height: 20px;
        text-align: center;
    }

    .doubleBoxContentContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .boxHeading {
        text-align: center;
        font-size: 24px;
        line-height: 30px;
    }

    .boxHeadingTwo {
        text-align: center;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
    }

    .boxOne {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .boxList {
        gap: 20px;
        width: 80vw;
    }

    .boxListKarma {
        gap: 20px;
        width: 80vw;
        padding-left: 5%;
    }

    .boxItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 5%;
    }

    .boxItemKarma {
        padding-bottom: 0%;
    }

    .boxItemImage {
        width: 20px;
        height: 20px;
        max-width: 20px;
        max-height: 20px;
    }

    .boxItemText {
        font-size: 12px;
        line-height: 18px;
    }

    /*---------------------------------------------*/

    /*---------------------------------------------*/

    .cbuttonContainer {
        padding: 16px 33.06px 14px 58px;
    }

    .buttonText {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
    }

    .buttonSVG {
        width: 12px;
        height: 8px;
    }

    .boxHeadingContainer{
        padding: 2%;
    }
}
