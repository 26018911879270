/* Navbar.css */
.navbar {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 0;
  z-index: 1000;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  width: 107px;
}

.links a {
  color: #fff;
  margin-right: 20px;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
}

.links a:hover {
  color: #ED765E;
}

/* Sliding indicator for active link */
.links a.active::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ED765E;
}


/*-------------------------------------------------------------------------------------------------------------------*/

/* Navbar.css */
@media only screen and (max-width: 768px) {
  .navbar {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 15px 0;
    z-index: 1000;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  .logo a {
    color: #fff;
    font-size: 24px;
    text-decoration: none;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px 0;
    transition: 0.4s;
  }

  .open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .open .bar:nth-child(2) {
    opacity: 0;
  }

  .open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .links {
    display: none;
  }

  .links.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: black;
  }

  .links a {
    color: #fff;
    margin: 10px 0;
    padding: 10px 20px;
    text-decoration: none;
  }

  .links a:hover {
    text-decoration: underline;
  }
}