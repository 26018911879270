.sectionWrapper {
  display: flex;
  width: 100vw;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.columnwhiteBox {
  display: flex;
  max-width: 1140px;
  padding: 20px 100px 20px 100px;
  flex-direction: column;
  align-items: center;
  border-radius: 60px;
  background: #FFF;
  border: 2px solid #ED765E;
}

.ldheadingContainer {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.ldheading {
  color: #000;
  text-transform: uppercase;
  font-family: Segoe UI;
  font-size: 41px;
  font-style: normal;
  font-weight: 900;
  line-height: 47.25px;
  padding: 2% 0%;
  /* 115.244% */
}

.ldlistContainer {
  display: flex;
  width: 975px;
  padding: 30px 0px;
justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}

.ldlist {
  display: flex;
  width: 315px;
  max-width: 1320px;
  padding: 0px 12px;
  align-items: flex-start;
  flex-shrink: 0;
}

.ldlistIconContainer {
  display: flex;
  padding: 0px 26.5px 80px 0px;
  align-items: flex-start;
  flex-shrink: 0;
}

.ldlistIcon {
  perspective: 1000px;
}

.ldlistSVG {
  width: 50px;
  height: 50px;
  transition: transform 0.8s;
}

.ldlist:hover .ldlistSVG {
  transform: rotateY(180deg);
}


.ldlistIcon {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.ldlistTextContainer {
  display: flex;
  width: 229.5px;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  flex-shrink: 0;
}

.ldlistHeading {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
  /* 138.889% */
}

.ldlistDesccontainer {
  display: flex;
  width: 229.5px;
  padding: 0px 13.5px 1px 0px;
  align-items: flex-start;
}

.ldlistDesc {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

/*----------------------------------------------------------------------*/

.ldimageImageContainer {
  display: flex;
  padding: 30px 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
}


/* Mobile Styles */
@media (max-width: 768px) {
  .columnwhiteBox {
    width: calc(100% - 40px); /* Adjust this value as needed */
    padding: 0;
  }

  .ldheading {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    padding-top: 10%;
  }

  .ldlistContainer {
    width: 80%;
    padding: 20px 10px;
    display: flex;
    align-items: center;
  }

  .ldlist {
    padding: 0 10px;
    margin-bottom: 20px;
  }

  .ldlistIconContainer {
    padding: 0 10px 20px 0;
  }

  .ldlistIcon {
    align-items: center;
  }

  .ldlistTextContainer {
    padding: 0;
  }

  .ldlistDesccontainer {
    padding: 0;
  }

  .ldlistHeading {
    font-size: 20px;
    line-height: 25px;
  }

  .ldlistDesc {
    font-size: 16px;
    line-height: 22px;
  }

  .ldimageImage{
    width: 80vw;
  }
}

/******************************************************************************************************************************/

@media screen and (min-width: 768px) and (max-width: 1024px) {

  .columnwhiteBox {
    width: calc(100% - 40px); /* Adjust this value as needed */
    padding: 0;
  }

  .ldheading {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    padding-top: 10%;
  }

  .ldlistContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    justify-items: center;
    width: 80vw;
  }
  
  .ldlist {
    width: 100%;
    max-width: 100%;
    padding: 0px 5px;
    align-items: flex-start;
  }
  
  

  .ldlistIconContainer {
    padding: 0 10px 20px 0;
  }

  .ldlistIcon {
    align-items: center;
  }

  .ldlistTextContainer {
    padding: 0;
  }

  .ldlistDesccontainer {
    padding: 0;
  }

  .ldlistHeading {
    font-size: 20px;
    line-height: 25px;
  }

  .ldlistDesc {
    font-size: 16px;
    line-height: 22px;
  }

  .ldimageImage{
    width: 80vw;
  }
}