.herosectionWrapper {
    display: flex;
    width: 100vw;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.heroBox {
    display: flex;
    max-width: 1140px;
    padding: 80px 100px 80px 60px;
    flex-direction: column;
    align-items: center;
    border-radius: 60px;
    background: transparent;
}

.heroContainer {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.heroTextContainer {
    padding-left: 50px;
    padding-right: 50px;
    width: 60%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
}

.heroTextWrapper {
    padding-bottom: 1px;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
}

.heroText {
    color: black;
    font-size: 46px;
    font-family: 'Inter', sans-serif;
    font-weight: 800;
    line-height: 47.50px;
    word-wrap: break-word;
}

.heroDescContainer {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;
}

.heroDesc {
    color: black;
    font-size: 15px;
    font-family: 'Inter', sans-serif;
    ;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
}

.heroEmailContainer {
    display: flex;
    background: white;
    border-radius: 5px;
    border: 1px #B9BEC7 solid;
    vertical-align: middle;
    width: 424px;
}

.heroEmail {
    color: #B9BEC7;
    text-decoration: none;
    font-size: 16px;
    font-family: Segoe UI;
    font-weight: 600;
    padding: 2% 0.5%;
}

.heroEmail::placeholder {
    color: #B9BEC7;
}

.heroButtonContainer {
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex;
}

.heroButton {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    background: linear-gradient(41deg, #ED765E 0%, #FEA858 50%, #EF7D5D 100%);
    box-shadow: 0px 9px 28px rgba(16, 30, 54, 0.15);
    border-radius: 5px;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s;
}

.heroButton::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ED765E;
    z-index: 1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s;
}

.heroButton:hover::before {
    transform: scaleX(1);
    transition: transform 0.5s;
}





.herobuttontext {
    color: white;
    font-size: 14px;
    font-family: Inter;
    font-weight: 600;
    line-height: 10px;
    word-wrap: break-word;
    z-index: 2;
}

.heroImageContainer {
    width: 40%;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;
}

.heroImageWrapper {
    width: 470px;
    height: 477px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.heroImage {
    width: 470px;
    height: 477.03px
}

/*-----------------------------------------------------------------------------------------*/

/* Media Query for Mobile Devices */

@media only screen and (max-width: 768px) {
    .herosectionWrapper {
        padding: 20px 0;
    }

    .heroBox {
        padding: 40px 30px;
    }

    .heroContainer {
        flex-direction: column;
    }

    .heroTextContainer {
        padding-left: 20px;
        padding-right: 20px;
        width: 80vw;
    }

    .heroText {
        font-size: 32px;
        text-align: center;
    }

    .heroDesc {
        text-align: center;
    }

    .heroEmailContainer {
        width: 100%;
    }

    .heroImageContainer {
        width: 100%;
    }

    .heroImageWrapper {
        width: 100%;
        height: auto;
    }

    .heroImage {
        width: 100%;
        height: auto;
    }

    .heroButtonContainer {
        padding-top: 10px;
        padding-bottom: 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        display: flex;
        width: 100%;
    }

    .heroContainer {
        width: 80vw;
        padding-top: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
}

/*****************************************************************************************************************/

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .herosectionWrapper {
        padding: 20px 0;
    }

    .heroBox {
        padding: 40px 30px;
    }

    .heroContainer {
        flex-direction: column;
    }

    .heroTextContainer {
        padding-left: 20px;
        padding-right: 20px;
        width: 80vw;
    }

    .heroText {
        font-size: 32px;
        text-align: center;
    }

    .heroDesc {
        text-align: center;
    }

    .heroEmailContainer {
        width: 100%;
    }

    .heroImageContainer {
        width: 100%;
    }

    .heroImageWrapper {
        width: 100%;
        height: auto;
    }

    .heroImage {
        width: 100%;
        height: auto;
    }

    .heroButtonContainer {
        padding-top: 10px;
        padding-bottom: 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        display: flex;
        width: 100%;
    }

    .heroContainer {
        width: 80vw;
        padding-top: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
}