.sectionWrapper {
    display: flex;
    width: 100vw;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.navtabBox {
    display: flex;
    max-width: 1140px;
    padding-top: 80px;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    border-radius: 60px;
    background: #EDFAFE;
}

.navtabContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.navtabHeadings {
    display: flex;
    padding: 0px 83px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.navtabSubHeading {
    color: #49CCF9;
    text-align: center;
    font-family: Segoe UI;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    /* 141.667% */
    letter-spacing: 1px;
    text-transform: uppercase;
}

.navtabHeading {
    color: #333;
    text-align: center;
    font-family: Segoe UI;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 65px;
    /* 135.417% */
}

.navtabComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

/*-------------------------------------------------------------------*/

@media screen and (max-width: 768px) {
    .navtabBox {
      padding: 40px 20px;
      border-radius: 30px;
      width: calc(100% - 40px);
    }
  
    .navtabHeadings {
      padding: 0 20px;
    }
  
    .navtabSubHeading {
      font-size: 10px;
    }
  
    .navtabHeading {
      font-size: 32px;
      line-height: 40px;
    }
  }
  
  /***************************************************************************************************************************/

  @media screen and (min-width: 768px) and (max-width: 1024px) {

    .navtabBox {
      padding: 40px 20px;
      border-radius: 30px;
      width: calc(100% - 40px);
    }
  
    .navtabHeadings {
      padding: 0 20px;
    }
  
    .navtabSubHeading {
      font-size: 10px;
    }
  
    .navtabHeading {
      font-size: 32px;
      line-height: 40px;
    }
  }