.sectionWrapper {
    display: flex;
    width: 100vw;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .subpageBox {
    display: flex;
    max-width: 1140px;
    padding: 20px 100px 50px 100px;
    flex-direction: column;
    align-items: center;
    border-radius: 60px;
    background: #FFF;
    border: 2px solid #ED765E;
  }
  
  .doubleBoxWrapper {
    display: flex;
    padding: 0px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.doubleBoxHeadingContainer {
    display: flex;
    width: 1000px;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 23px;
}

.doubleBoxHeadingWrapper {
    display: flex;
    width: 1000px;
    max-width: 1000px;
    padding: 0px 20px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.doubleBoxMainHeading {
    color: #000;
    text-align: center;
    font-family: Segoe UI;
    font-size: 41px;
    font-style: normal;
    font-weight: 900;
    line-height: 47.25px;
    /* 115.244% */
}

.doubleboxSubHeading {
    color: #333;
    font-family: Inter;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
}

  

  /* Mobile Styles */
  @media (max-width: 768px) {
    .subpageBox {
      width: calc(100% - 40px); /* Adjust this value as needed */
      padding: 0;
      padding-bottom: 5%;
    }
  
    .doubleBoxWrapper {
        padding: 0;
        justify-content: center;
        align-items: center;
    }

    .doubleBoxHeadingWrapper {
        padding: 0;
    }

    .doubleBoxMainHeading {
        padding-top: 5%;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
    }

    .boxHeadingKarma {
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        width: 80vw;
    }

    .doubleboxSubHeading {
        font-size: 12px;
        width: 80vw;
        line-height: 20px;
        text-align: center;
    }
  
  }
  /******************************************************************************************************************************/
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
  
    .subpageBox {
      width: calc(100% - 40px); /* Adjust this value as needed */
      padding: 0;
      padding-bottom: 5%;
    }
  
    .doubleBoxWrapper {
        padding: 0;
        justify-content: center;
        align-items: center;
    }

    .doubleBoxHeadingWrapper {
        padding: 0;
    }

    .doubleBoxMainHeading {
        padding-top: 5%;
        font-size: 32px;
        line-height: 30px;
        text-align: center;
    }

    .boxHeadingKarma {
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        width: 80vw;
    }

    .doubleboxSubHeading {
        font-size: 12px;
        width: 80vw;
        line-height: 20px;
        text-align: center;
    }

  }