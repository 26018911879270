.navtabcontentContainer {
    display: flex;
    height: 577px;
    padding-top: 32px;
    align-items: center;
    align-content: flex-start;
    gap: 0px 32px;
    flex-wrap: wrap;
}

.contentBody {
    display: flex;
    width: 50%;
    padding-bottom: 0px;
    padding-left: 10%;
    flex-direction: column;
    align-items: flex-start;
    gap: 31.98px;
}

.contentList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contentListItem {
    display: flex;
    padding: 8px 0px;
    align-items: flex-start;
    gap: 30px;
}

.contentItemIcon {
    width: 20%;
    justify-content: center;
    align-items: center;
    perspective: 1000px;
}

.contentItemPNG {
    width: 50px;
    height: 50px;
    transition: transform 0.8s;
}

.contentItemIcon:hover .contentItemPNG {
    transform: rotateY(180deg);
}

.contentItemBody {
    display: flex;
    padding-bottom: 0px;
    width: 80%;
    flex-direction: column;
    align-items: flex-start;
    gap: 9.78px;
}

.contentItemBodyHeading {
    color: #292D34;
    font-family: Segoe UI;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 23.375px;
    /* 137.5% */
}

.contentItemBodyDescWrapper {
    display: flex;
    padding: 0px 37px 0.4px 0px;
    align-items: flex-start;
}

.contentItemBodyDesc {
    color: #292D34;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    /* 178.571% */
}

.contentButton {
    display: flex;
    padding: 19.2px 34.04px 20.8px 30px;
    align-items: center;
    border-radius: 9px;
    background: #49CCF9;
}

.contentButton:hover {
    background: #2491b6;
}

.contentButtonText {
    color: #FFF;
    font-family: Segoe UI;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 21px;
    /* 150% */
}

.contentScreenshotContainer {
    display: flex;
    width: 40%;
    padding-bottom: 0px;
    justify-content: flex-end;
    align-items: flex-start;
}

.contentScreenshot {
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    border-radius: 15px;
}

/*----------------------------------------------------------------------------------------------------------------------*/

@media only screen and (max-width: 768px) {
    .navtabcontentContainer {
        display: flex;
        height: auto;
        padding-top: 32px;
        align-items: center;
        justify-content: center;
        gap: 0px 32px;
        flex-wrap: wrap;
    }

    .contentBody {
        display: flex;
        width: 100%;
        padding: 0 20px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .contentList {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contentListItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 80vw;
        text-align: center;
    }

    .contentItemIcon {
        justify-content: center;
        align-items: center;
    }

    .contentItemPNG {
        width: 50px;
        height: 50px;
    }

    .contentItemBody {
        display: flex;
        padding-bottom: 0px;

    }

    .contentItemBodyHeading {
        font-size: 14px;
        line-height: 19px;
    }

    .contentItemBodyDescWrapper {
        padding: 0px 10px 0.4px 0px;
    }

    .contentItemBodyDesc {
        font-size: 12px;
        line-height: 20px;
        text-align: left;
    }

    .contentButton {
        padding: 12px 24px;
    }

    .contentButtonText {
        font-size: 12px;
        line-height: 18px;
    }

    .contentScreenshotContainer {
        padding: 20px 0;
        order: -1;
        width: 80vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contentScreenshot {
        padding: 10px;
        border-radius: 15px;
    }
}

/********************************************************************************************************************/

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .navtabcontentContainer {
        display: flex;
        height: auto;
        padding-top: 32px;
        align-items: center;
        justify-content: center;
        gap: 0px 32px;
        flex-wrap: wrap;
    }

    .contentBody {
        display: flex;
        width: 100%;
        padding: 0 20px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .contentList {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contentListItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 80vw;
        text-align: center;
    }

    .contentItemIcon {
        justify-content: center;
        align-items: center;
    }

    .contentItemPNG {
        width: 50px;
        height: 50px;
    }

    .contentItemBody {
        display: flex;
        padding-bottom: 0px;

    }

    .contentItemBodyHeading {
        font-size: 14px;
        line-height: 19px;
    }

    .contentItemBodyDescWrapper {
        padding: 0px 10px 0.4px 0px;
    }

    .contentItemBodyDesc {
        font-size: 12px;
        line-height: 20px;
        text-align: left;
    }

    .contentButton {
        padding: 12px 24px;
    }

    .contentButtonText {
        font-size: 12px;
        line-height: 18px;
    }

    .contentScreenshotContainer {
        padding: 20px 0;
        order: -1;
        width: 60vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contentScreenshot {
        padding: 10px;
        border-radius: 15px;
    }
}