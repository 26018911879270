.sectionWrapper {
    display: flex;
    width: 100vw;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.supportBox {
    display: flex;
    max-width: 1140px;
    padding: 80px 100px 80px 100px;
    flex-direction: column;
    align-items: center;
    border-radius: 60px;
    background: #FFF;
}

.supportWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.supportTextContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.supportHeadings {
    display: flex;
    width: 440px;
    padding: 0.39px 74.4px 0px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 19.61px;
}

.supportTopHeading {
    color: #7B68EE;
    font-family: Segoe UI;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    /* 141.667% */
    letter-spacing: 1px;
    text-transform: uppercase;
}

.supportHeadingWrapper {
    display: flex;
    max-width: 365.6px;
    padding-right: 0px;
    align-items: flex-start;
}

.supportHeading {
    color: #333;
    font-family: Segoe UI;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 65px;
    /* 135.417% */
}

.supportDescWrapper {
    display: flex;
    width: 440px;
    padding: 0px 98px 0.4px 0px;
    align-items: flex-start;
}

.supportDesc {
    color: #292D34;
    font-family: Segoe UI;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    /* 192.308% */
}

.supportImage {
    display: flex;
    width: 618px;
    height: 470.45px;
    max-width: 618px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

/*-------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 768px) {
    .sectionWrapper {
        padding: 20px 10px;
    }

    .supportBox {
        padding: 40px;
        width: calc(100% - 40px);
    }

    .supportWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .supportTextContainer {
        align-items: center;
    }

    .supportHeadings {
        align-items: center;
        text-align: center;
        padding: 0;
    }

    .supportHeading {
        font-size: 28px;
    }

    .supportDescWrapper {
        width: 70vw;
        align-items: center;
        text-align: center;
        padding: 0 2%;
    }

    .supportDesc {
        font-size: 14px;
        line-height: 20px;
    }

    .supportImage {
        width: 70vw;
        height: auto;
    }
}

/*****************************************************************************************************************/

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .sectionWrapper {
        padding: 20px 10px;
    }

    .supportBox {
        padding: 40px;
        width: calc(100% - 40px);
    }

    .supportWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .supportTextContainer {
        align-items: center;
    }

    .supportHeadings {
        align-items: center;
        text-align: center;
        padding: 0;
    }

    .supportHeading {
        font-size: 28px;
    }

    .supportDescWrapper {
        width: 70vw;
        align-items: center;
        text-align: center;
        padding: 0 2%;
    }

    .supportDesc {
        font-size: 14px;
        line-height: 20px;
    }

    .supportImage {
        width: 70vw;
        height: auto;
    }
}