.sectionWrapper {
    display: flex;
    width: 100vw;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.pinkBox {
    display: flex;
    max-width: 1140px;
    padding: 80px 100px 80px 60px;
    flex-direction: column;
    align-items: center;
    border-radius: 60px;
    background: #FFF8FB;
}

.contentContainer {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  gap: -1.4px;
}

.row {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: center;
    gap: 23px;
}

.rowBottom {
    display: flex;
    padding-bottom: 0px;
    flex-direction: row;
    align-items: center;
    gap: 23px;
}

.subHeading {
    display: flex;
    padding: 0px 469.69px 0.75px 468.31px;
    justify-content: center;
    align-items: flex-start;
}

.subHeadingText {
    color: #767676;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    width: 90px;
    font-weight: 800;
    /* 112.5% */
    letter-spacing: 1px;
    text-transform: uppercase;
}

.heading {
    display: flex;
    padding: 0px 207.03px 0.25px 207.97px;
    justify-content: center;
    align-items: flex-start;
}

.headingText {
    color: #000;
    text-align: center;
    font-family: Segoe UI;
    font-size: 41px;
    font-style: normal;
    font-weight: 800;
}

.listItem {
    display: flex;
    padding: 0px 0px 27px 27px;
    flex-direction: row;
    align-items: flex-start;
    gap: 7px;
}

.listContainer {
    display: flex;
    padding-left: 5%;
    padding-top: 5%;
    flex-direction: column;
    align-items: flex-start;
}

.listIcon {
    width: 27px;
    height: 27px;
}

.listItemRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.textColumn {
    display: flex;
    flex: 6;
    padding-left: 15px;
}

.imageColumn {
    display: flex;
    flex: 4;
    vertical-align: middle;
}

.listHeading {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 25px;
    /* 138.889% */
}

.listBody {
    padding-top: 10px;
    color: #333;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
}

/*---------------------------------------------------------------------------------------------------*/

/* Your existing CSS */

/* Media Query for Mobile Devices */
@media screen and (max-width: 768px) {
  .sectionWrapper {
    padding: 40px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pinkBox {
    flex-direction: column;
    padding: 20px;
    width: calc(100% - 40px);
  }

  .subHeading {
    padding: 0 20px;
    text-align: center;
  }

  .subHeadingText {
    font-size: 16px; /* Adjusted font size */
  }

  .heading {
    padding: 20px 20px 0;
    text-align: center;
  }

  .headingText {
    font-size: 32px; /* Adjusted font size */
  }

  .rowBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }

  .textColumn {
    flex: 1;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .imageColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    order: -1;
  }

  .listContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0% 2%;
  }

  .listItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 20px 20px;
  }

  .listIcon {
    display: none;
  }

  .listItemRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .listHeading {
    font-size: 18px; /* Adjusted font size */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 15px;
  }

  .listBody {
    text-align: center;
    padding: 0 15px;
  }

  .pinkBoxImage {
    width: 100%;
    max-width: 300px;
  }
}

/**********************************************************************************************************************/

@media screen and (min-width: 768px) and (max-width: 1024px) {

  .sectionWrapper {
    padding: 40px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pinkBox {
    flex-direction: column;
    padding: 20px;
    width: calc(100% - 40px);
  }

  .subHeading {
    padding: 0 20px;
    text-align: center;
  }

  .subHeadingText {
    font-size: 16px; /* Adjusted font size */
  }

  .heading {
    padding: 20px 20px 0;
    text-align: center;
  }

  .headingText {
    font-size: 32px; /* Adjusted font size */
  }

  .rowBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }

  .textColumn {
    flex: 1;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .imageColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    order: -1;
  }

  .listContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0% 2%;
  }

  .listItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 20px 20px;
  }

  .listIcon {
    display: none;
  }

  .listItemRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .listHeading {
    font-size: 18px; /* Adjusted font size */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 15px;
  }

  .listBody {
    text-align: center;
    padding: 0 15px;
  }

  .pinkBoxImage {
    width: 100%;
    max-width: 300px;
  }
}